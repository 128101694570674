import imageRoscoKalis from 'common/src/assets/images/app/testimonial/roscokalis.jpg';
import imageJohnNieri from 'common/src/assets/images/app/testimonial/johnnieri.jpg';
import imageJasonCox from 'common/src/assets/images/app/testimonial/jasonbcox.jpg';
import imageVinArmani from 'common/src/assets/images/app/testimonial/vinarmani.jpg';
import imageTylerSmith from 'common/src/assets/images/app/testimonial/tylersmith.jpg';
import imageJustinBons from 'common/src/assets/images/app/testimonial/justinbons.jpg';
import imageAmaurySechet from 'common/src/assets/images/app/testimonial/amaurysechet.jpg';

const data = {
  thumbs: [imageRoscoKalis, imageVinArmani, imageJasonCox, imageJohnNieri, imageTylerSmith, imageJustinBons, imageAmaurySechet],
  testimonials: [
    {
      key: 'RoscoKalis',
      author: 'Rosco Kalis',
      url: 'https://twitter.com/RoscoKalis/status/1193915957233635328',
      thumb: imageRoscoKalis
    },
    {
      key: 'VinArmani',
      author: 'Vin Armani',
      url: 'https://twitter.com/vinarmani/status/1193920092481785857',
      thumb: imageVinArmani
    },
    {
      key: 'JasonBCox',
      author: 'Jason B. Cox',
      url: 'https://twitter.com/jasonbcox0/status/1194037560596525056',
      thumb: imageJasonCox
    },
    {
      key: 'JohnNieri',
      author: 'John Nieri',
      url: 'https://twitter.com/EmergentReasons/status/1193930567269511168',
      thumb: imageJohnNieri
    },
    {
      key: 'TylerSmith',
      author: 'Tyler Smith',
      url: 'https://twitter.com/tcrypt25519/status/1194335961053683713',
      thumb: imageTylerSmith
    },
    {
      key: 'JustinBons',
      author: 'Justin Bons',
      url: 'https://twitter.com/Justin_Bons/status/1194366353496584192',
      thumb: imageJustinBons
    },
    {
      key: 'AmaurySechet',
      author: 'Amaury Séchet',
      thumb: imageAmaurySechet
    },
  ]
};
export default data;
